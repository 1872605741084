<form (ngSubmit)="(forEdit || !useMultipay) ? onSubmit_validateAndSubmit($event) : onSubmit_openIframe($event)">
    <ng-container *ngIf="forEdit || !useMultipay">
        <section [formGroup]="paymentDetailsForm"
            class="payment-method-details layout-grid"
            [ngClass]="{
                    'payment-method-details--credit': paymentMethodData.type == PaymentMethodTypeEnum.CreditCard,
                    'payment-method-details--echeck': paymentMethodData.type == PaymentMethodTypeEnum.ECheck
                }">
            <mat-form-field appearance="standard" floatLabel="always" class="card-holder-name" *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
                <mat-label>{{CC_FIELDS.ccname}}</mat-label>
                <input matInput [formControlName]="CC_FIELDS.ccname" required maxlength="50"
                    placeholder="Name as shown on card">
                <mat-error
                    *ngIf="paymentDetailsForm.controls?.[CC_FIELDS.ccname]?.touched && paymentDetailsForm?.controls?.[CC_FIELDS.ccname]?.invalid">
                    {{validationService.getFieldErrorMessage(CC_FIELDS.ccname, paymentDetailsForm.controls[CC_FIELDS.ccname])}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="echeck__name" appearance="standard" floatLabel="always" *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
                <mat-label>{{ECHECK_FIELDS.checkname}}</mat-label>
                <input matInput
                    [formControlName]="ECHECK_FIELDS.checkname"
                    placeholder="Name on the account"
                    required>
                <mat-error
                    *ngIf="paymentDetailsForm?.controls?.[ECHECK_FIELDS.checkname]?.touched && paymentDetailsForm?.controls?.[ECHECK_FIELDS.checkname]?.invalid">
                    {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkname, paymentDetailsForm.controls[ECHECK_FIELDS.checkname])}}
                </mat-error>
            </mat-form-field>

            <button class="fis__payment-info__button"
                mat-flat-button color="accent"
                *ngIf="tokenForEdit(TokenizerAction.EditExisting)"
                type="button"
                (click)="onClick_openIFrame($event, TokenizerAction.EditExisting)"
                (keyup.enter)="onEnter_preventDefault($event)"
                [disabled]="!fisFieldsAvailable">
                Edit Payment Info
            </button>

            <button class="fis__payment-info__button"
                mat-flat-button color="accent"
                type="button"
                (click)="onClick_openIFrame($event, TokenizerAction.AddNew)"
                (keyup.enter)="onEnter_preventDefault($event)"
                [disabled]="!fisFieldsAvailable">
                {{ tokenForEdit(TokenizerAction.EditExisting) ? 'Re-Enter' : 'Enter' }} Payment Info
            </button>

            <ng-template #fisIframe>
                <button type="button" mat-dialog-close id="fis-close-modal" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>

                <fis-tokenization id="fis-iframe"></fis-tokenization>

                <div class="fis-load-panel" *ngIf="!fisIframeLoaded">
                    <mat-spinner color="accent" diameter="32" class="fis-loader"></mat-spinner>
                    Loading...
                </div>
            </ng-template>

            <ng-container *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
                <div class="payment-method-details__card-info">
                    <mat-form-field appearance="standard">
                        <mat-label>{{CC_FIELDS.ccnumber}}</mat-label>
                        <input matInput disabled
                            [value]="paymentMethodData.cardNumber ? '**** **** **** ' + paymentMethodData.cardNumber : '---- ---- ---- ----'">
                        <mat-error *ngIf="fisFieldError">{{ fisFieldError }}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>{{CC_FIELDS.ccexp}}</mat-label>
                        <input matInput disabled
                            [value]="paymentMethodData.cardExpiry ? paymentMethodData.cardExpiry : 'MM/YY' ">
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
                <div class="payment-method-details__bank-info">
                    <mat-form-field class="account-type" appearance="standard" floatLabel="always">
                        <mat-label>{{ECHECK_FIELDS.checktype}}</mat-label>
                        <mat-select
                            [formControlName]="ECHECK_FIELDS.checktype"
                            required>
                            <mat-option selected value="">-- Select One --</mat-option>
                            <mat-option *ngFor="let accountType of ECHECK_ACCOUNTTYPELIST" [value]="accountType">
                                {{ accountType }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="paymentDetailsForm?.controls?.[ECHECK_FIELDS.checktype]?.touched && paymentDetailsForm?.controls?.[ECHECK_FIELDS.checktype]?.invalid">
                            {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checktype, paymentDetailsForm.controls[ECHECK_FIELDS.checktype])}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="account-owner-type" appearance="standard" floatLabel="always">
                        <mat-label>{{ECHECK_FIELDS.checkowner}}</mat-label>
                        <mat-select
                            [formControlName]="ECHECK_FIELDS.checkowner"
                            required>
                            <mat-option selected value="">-- Select One --</mat-option>
                            <mat-option *ngFor="let accountOwnerType of ECHECK_ACCOUNTOWNERTYPELIST" [value]="accountOwnerType">
                                {{ accountOwnerType }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="paymentDetailsForm?.controls?.[ECHECK_FIELDS.checkowner]?.touched && paymentDetailsForm?.controls?.[ECHECK_FIELDS.checkowner]?.invalid">
                            {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkowner, paymentDetailsForm.controls[ECHECK_FIELDS.checkowner])}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>{{ECHECK_FIELDS.checkaba}}</mat-label>
                        <input matInput disabled
                            [value]="paymentMethodData.echeckRoutingNumber ? '*****' + paymentMethodData.echeckRoutingNumber.slice(-4) : '----' ">
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>{{ECHECK_FIELDS.checkaccount}}</mat-label>
                        <input matInput disabled
                            [value]="(paymentMethodData.echeckAccountNumber ? '*****' + paymentMethodData.echeckAccountNumber.slice(-4) : '----') ">
                    </mat-form-field>
                </div>

                <div class="echeck-instructions">
                    <div class="echeck-instructions__note">
                        <teller-online-icon-info></teller-online-icon-info>
                        <span>Where do I find Routing and Account Numbers?</span>
                    </div>

                    <img class="echeck-instructions__sample" src="assets/images/echeck_latest.png" alt="Sample check image">
                </div>
            </ng-container>

            <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
                <div class="checkbox remember-payment-method" formGroupName="rememberPaymentMethod">
                    <mat-checkbox formControlName="checked">
                        Save {{paymentMethodData.type == PaymentMethodTypeEnum.CreditCard ? 'credit card' : 'E-Check'}} information
                    </mat-checkbox>
                </div>
            </ng-container>
        </section>

        <h2>Billing Address</h2>

        <app-billing-info
            [forEdit]="forEdit"
            [billingInfo]="paymentMethodData.billingInfo"
            [requiredFields]="requiredAddressFields"
            [disabledFields]="disabledAddressFields"
            [formGroup]="paymentDetailsForm">
        </app-billing-info>

        <app-echeck-disclaimer
            *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard"
            [forEdit]="forEdit"
            [eCheckMessage]="creditDisclaimer"
            (eCheckCheckChange)="onCheck_agreeCreditCardDisclaimer($event)">
        </app-echeck-disclaimer>
        <app-echeck-disclaimer
            *ngIf="(forEdit ? eCheckDisclaimer : eCheckConfirmationMessage)
                && paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
            [forEdit]="forEdit"
            [eCheckMessage]="forEdit ? eCheckDisclaimer : eCheckConfirmationMessage"
            (eCheckCheckChange)="onCheck_agreeECheckDisclaimer($event)">
        </app-echeck-disclaimer>

        <re-captcha #reCaptcha (resolved)="onResolved_confirmCaptcha($event)" siteKey="{{captchaCode}}"></re-captcha>

        <button
            mat-flat-button
            color="accent"
            type="submit"
            [disabled]="!fisFieldsAvailable || shouldDisableECheckSubmit() || shouldDisableCreditCardSubmit()">
            {{forEdit ? 'Save' : 'Pay'}}
        </button>
    </ng-container>
    <ng-container *ngIf="!forEdit && useMultipay" class="fis__multipay">
        <section [formGroup]="paymentDetailsForm" class="payment-method-details layout-grid">
            <mat-form-field appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput 
                    formControlName="Email"
                    inputmode="email" 
                    required 
                    [pattern]="validationService.emailRegex">
                <mat-error *ngIf="paymentDetailsForm?.controls?.['Email'].touched && paymentDetailsForm?.controls?.['Email'].invalid">
                    {{validationService.getFieldErrorMessage('Email', paymentDetailsForm.controls['Email'], validationService.fieldErrors['Email'])}}
                </mat-error>
            </mat-form-field>
        </section>

        <app-echeck-disclaimer
            *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard"
            [forEdit]="false"
            [eCheckMessage]="creditDisclaimer"
            (eCheckCheckChange)="onCheck_agreeCreditCardDisclaimer($event)">
        </app-echeck-disclaimer>

        <app-echeck-disclaimer
            *ngIf="eCheckConfirmationMessage
                && paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
            [forEdit]="false"
            [eCheckMessage]="eCheckConfirmationMessage"
            (eCheckCheckChange)="onCheck_agreeECheckDisclaimer($event)">
        </app-echeck-disclaimer>

        <re-captcha #reCaptcha (resolved)="onResolved_confirmCaptcha($event)" siteKey="{{captchaCode}}"></re-captcha>

        <button
            mat-flat-button
            color="accent"
            type="submit"
            [disabled]="shouldDisableECheckSubmit() || shouldDisableCreditCardSubmit()">
            Pay
        </button>

        <multipay-web></multipay-web>

        <ng-template #fisIframe>
            <button class="fis__multipay__close" type="button" mat-dialog-close id="fis-close-modal" aria-label="Close dialog" (click)="onClick_refreshCart()">
                <mat-icon>close</mat-icon>
            </button>

            <div id="fis-iframe"></div>

            <div class="fis-load-panel" *ngIf="!fisIframeLoaded">
                <mat-spinner color="accent" diameter="32" class="fis-loader"></mat-spinner>
                Loading...
            </div>
        </ng-template>
    </ng-container>
</form>