// Angular Files
import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Other External Files
import { firstValueFrom, take } from 'rxjs';

// Payment Integration Files
import {
    IFramePaymentResponse,
    IFrameSavePaymentMethodResponse,
    PaymentMethodData,
    PaymentMethodTypeEnum,
    PaymentMethodTypeEnumConvertor,
} from 'apps/public-portal/src/app/payment-integrations/base';
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import {
    FisIntegrationApiClient,
    FisPaymentDetailsRequestDto,
    FisPaymentDetailsResponseDto,
    FisVerifyPaymentRequestDto,
    IFramePaymentRequestDto,
    IFramePaymentResponseDto,
    IFrameSavePaymentMethodRequestDto,
    IFrameSavePaymentMethodResponseDto,
    PaymentStatusEnumDto,
    SessionTokenResponseDto,
    SharedPaymentIntegrationApiClient
} from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import { CartService } from 'apps/public-portal/src/app/core/services';
import {
    IFramePaymentPrepareResponse
} from 'apps/public-portal/src/app/payment-integrations/base/models/IFramePaymentPrepareResponse';
import { TellerOnlineDialogAction, TellerOnlineMessageService } from "teller-online-libraries/shared";

@Injectable({
    providedIn: 'root'
})
export class FisService extends PaymentProcessorService {
    //#region PaymentProcessorService fields

    // The endpoint used to load Component.js
    public tokenizationUrl: string = null;
    public multiPayMerchantAlias: string = null;
    public multiPayIFrameUrl: string = null;
    public useMultiPay: boolean | undefined = null;

    //#endregion

    constructor(
        private currencyPipe: CurrencyPipe,
        private fisApi: FisIntegrationApiClient,
        private router: Router,
        private messageService: TellerOnlineMessageService,
        public cartService: CartService,
        sharedPaymentApi: SharedPaymentIntegrationApiClient
    ) {
        super(sharedPaymentApi);
    }

    //#region PaymentProcessorService

    public override async checkout(cartGuid: string) {
        this.router.navigate(['/checkout']);
    }

    public override get hasPrepareStep() { return true; }

    public async preparePayment(params: any): Promise<any> {
        let response = await this.preparePaymentAndAssessFees(params);
        return new FisPaymentPrepareResponse(response);
    }

    public override async payCart(params): Promise<IFramePaymentResponse> {
        let request = this._buildDto(params);

        // Make the sale (Validate cart, complete payment transaction, post to teller).
        let response = await firstValueFrom(
            this.sharedPaymentApi.makePayment(request)
        );

        return new IFramePaymentResponse({
            emailAddress: response.emailAddress,
            errorMessage: response.responseMessage,
            cartStatus: response.responseSuccess
        });
    }

    public override async savePaymentMethod(params): Promise<IFrameSavePaymentMethodResponse> {
        let request = new IFrameSavePaymentMethodRequestDto();
        let paymentMethodData: PaymentMethodData = params.paymentMethodData;

        request.captchaToken = params.captchaToken;

        this.mapRequest(request, paymentMethodData, params.paymentToken);
        let response: IFrameSavePaymentMethodResponseDto;
        if (params.paymentMethodId) {
            response = await firstValueFrom(this.sharedPaymentApi.updatePaymentMethod(params.paymentMethodId, request));
        } else {
            response = await firstValueFrom(this.sharedPaymentApi.addPaymentMethod(request));
        }

        return new IFrameSavePaymentMethodResponse({
            paymentMethodId: response.paymentMethodId,
            last4: request.last4
        });
    }

    /** Used for MultiPay scenarios.
     * Note, because this is used for multipay, the payment should start in Processing, not PreProcessing.
     * Unlike the tokenization payments which normally start in PreProcessing.
     * As a note, Processing is the default for this method in general, but because it's different from how FIS functions normally I
     * specified it explicitly
     */
    public async startPayment(cartGuid: string): Promise<string> {
        const response = await firstValueFrom(this.sharedPaymentApi.startPayment(cartGuid, PaymentStatusEnumDto.Processing));
        return response.paymentIdentifier;
    }
    //#endregion

    //#region fis methods

    public async requestJwt(paymentMethodType: PaymentMethodTypeEnum, throwError: boolean = false, forMultiPay: boolean = false): Promise<SessionTokenResponseDto> {
        try {
            return await firstValueFrom(this.fisApi.requestJwt(PaymentMethodTypeEnumConvertor.toDto(paymentMethodType), forMultiPay));
        } catch (e) {
            if (throwError) {
                throw e;
            } else {
                this._errorEvent.next('Failed to generate session token');
            }
        }
    }

    /** Used for MultiPay scenarios */
    public async getPaymentDetails(cartGuid: string, captchaToken: string): Promise<FisPaymentDetailsResponseDto> {
        const body = new FisPaymentDetailsRequestDto({ cartGuid, captchaToken });
        return await firstValueFrom(this.fisApi.fisGeneratePaymentDetails(body));
    }

    /** Used for MultiPay scenarios */
    public async verifyPayment(cartGuid: string, paymentMethodData: PaymentMethodData, paymentId: string, paymentIdentifier: string): Promise<IFramePaymentResponse> {
        const request = new FisVerifyPaymentRequestDto({paymentId, cartGuid, paymentIdentifier});
        if (paymentMethodData != null) {
            request.last4 = paymentMethodData.last4Digits;
            request.cardType = paymentMethodData.cardType;
            request.email = paymentMethodData.billingInfo.email;
            request.processorPaymentMethodType = paymentMethodData.processorPaymentMethodType;
        }
        const response = await firstValueFrom(this.fisApi.fisVerifyPayment(request));
        
        return new IFramePaymentResponse({
            emailAddress: response.emailAddress,
            errorMessage: response.responseMessage,
            cartStatus: response.responseSuccess
        });
    }
    //#endregion

    //#region helpers

    /**
     * Because it is possible in our flow for a user to not see the convenience fees
     * this dialog needs to be called when they change before a payment is made.
     * This way the user understands the credit card cost and has the opportunity to stop
     * and choose another payment method.
     */
    public showConfirmationDialog(convenienceFee?: number): void {
        this.cartService.cart$.pipe(take(1)).subscribe(cart => {
            const subtotal = cart.items.reduce((acc, item) => (item.amountInCart * (item.quantity ?? 1)) + acc, 0);
            this.messageService.custom({
                content: `
                    <div class="fis-review-and-confirm-fees-dialog__row"><div>Subtotal</div><div>${this.currencyPipe.transform(subtotal)}</div></div>
                    <div class="fis-review-and-confirm-fees-dialog__row"><div>Convenience Fee</div><div>${this.currencyPipe.transform(convenienceFee ?? 0)}</div></div>
                    <div class="fis-review-and-confirm-fees-dialog__row"><div>Total</div><div>${this.currencyPipe.transform(subtotal + (convenienceFee ?? 0))}</div></div>
                `,
                title: "",
                actions: [
                    new TellerOnlineDialogAction({
                        text: "Continue",
                        close: false
                    })
                ]
            },
                // this class is defined in the checkout.component.scss as that should always be on the page
                // because this dialog should only be shown in the checkout process
                "fis-review-and-confirm-fees-dialog");
        });
    }

    public async preparePaymentAndAssessFees(params): Promise<IFramePaymentPrepareResponse> {
        let request = this._buildDto(params);

        // Just prepare the payment for submission to receive the calculated fees,
        // but do not submit the payment until the user approves the fees.
        let response = await firstValueFrom(this.fisApi.preparePaymentAndAssessFees(request));

        return new IFramePaymentPrepareResponse({
            paymentToken: response.paymentToken,
            convenienceFee: response.convenienceFee,
            processorPaymentMethodType: response.processorPaymentMethodType
        });
    }

    public _buildDto(params): IFramePaymentRequestDto {
        let request = new IFramePaymentRequestDto();

        let paymentMethodData: PaymentMethodData = params.paymentMethodData;
        request.cartId = params.cartId;
        request.paymentMethodType = PaymentMethodTypeEnumConvertor.toDto(params.paymentMethodType ?? paymentMethodData.type);
        request.inboundRedirectSourceId = params.inboundRedirectSourceId;
        request.validationToken = params.validationToken;
        request.processorPaymentMethodType = params.processorPaymentMethodType;
        request.captchaToken = params.captchaToken;

        if (!params.saved) {
            this.mapRequest(request, paymentMethodData, params.paymentToken);
        }

        return request;
    }

    //#endregion
}

/** Helper class to ease converting IFramePaymentPrepareResponse to an object
 * with keys that match that of the params that payCart is expecting.
 */
class FisPaymentPrepareResponse {
    public validationToken: string;
    public convenienceFee: number;
    public processorPaymentMethodType: string;

    constructor(response: IFramePaymentPrepareResponse) {
        this.validationToken = response.paymentToken;
        this.convenienceFee = response.convenienceFee
        this.processorPaymentMethodType = response.processorPaymentMethodType;
    }
}
